<template>
  <div class="p-grid">
    <div style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff">
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0" style="line-height: .9">
          <Button v-tooltip.bottom="'Add text template'" class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus" style="float: right;" @click="initNewTextTemplateDialog()"/>
          <strong>{{ _t('label_text_templates') }}</strong>
        </h3>
        <br>
        <MultiSelectableBreadcrumb ref="breadcrumbData" single-selection></MultiSelectableBreadcrumb>
        <div>
        </div>
      </div>
      <span class="p-input-icon-right p-m-3" style="width: calc(100% - 3rem)">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="userGroupSearch" style="width:100%; margin: 5px;" type="text"/>
        </span>

      <DataTable
          ref="tree"
          v-model:selection="selectedUGItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="currentPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="UGTableRows"
          :scrollable="true"
          :totalRecords="totalUGRecords"
          :value="userGroups"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
      >
        <Column
            field="name"
            header="Group"
            style="width: 100%; word-break: break-all;min-height: 38px;"
        >
          <template #body="{data}">
                <span class="wrap"
                      style="word-break: break-all; padding-left: 5px;">
             {{ data.surname }} {{ data.name }}
               </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <user-group-item :parentData="selectedUGItem? selectedUGItem : {}" class="style p-col"/>
  </div>

  <Dialog v-model:visible="newTextTemplateDialog" :style="{ width: '450px' }">
    <template #header>
      <h3>{{ _t('label_create_text_template') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <Textarea
              v-model="newTextTemplate.text"
              :placeholder="_t('label_text_template')"
              class="p-m-0"
              rows="5"
              style="width:100%"/>
        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <MultiSelect ref="groupPG"
                       v-model="newTextTemplate.userGroupIds"
                       :filter="true"
                       :options="userGroups"
                       :style="{width: '20vw'}"
                       optionLabel="name" optionValue="id"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="newTextTemplateDialog = false"/>
      <Button :disabled="!(newTextTemplate.userGroupIds.length > 0 && newTextTemplate.text.length > 0)"
              :label="_t('Save')" autofocus
              class="p-button-success" @click="createTextTemplate()"/>
    </template>
  </Dialog>
</template>

<script>
import {getList} from '@/api/list'
import {getTree} from "@/api/tree";
import router from "@/router";
import userGroupItem from "./item"
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import {create} from '@/api/text-templates'
import {findUserGroup} from '@/api/userGroup'

export default {
  name: 'index',
  components: {
    'userGroupItem': userGroupItem,
    'MultiSelectableBreadcrumb': MultiSelectableBreadcrumb
  },
  data() {
    return {
      breadcrumbs: [{'label': 'Total system'}],
      orgName: 'Total system',
      userGroupSearch: '',
      dataTabs: false,
      selectedUGItem: {
        id: null
      },
      userGroups: null,
      totalUGRecords: 100,
      UGTableRows: 100,
      currentPage: 0,
      organizations: [],
      selectedNode: null,
      loading: true,
      statuses: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      selectedTreeTableValue: null,
      treeParams: 'category_filter=GEOGRAPHICAL_SCOPE',
      newTextTemplateDialog: false,
      selectParentOrgDialog: false,
      expandedOrganizations: [],
      newUserGroup: {},
      orgId: localStorage.getItem('orgId') ?? 1,
      ugSearch: '',
      newTextTemplate: {
        text: '',
        userGroupIds: []
      }
    }
  },
  mounted() {
    this.getUserGroups()
    this.getOrganizations()
    this.checkOrgId()
    let groupId = this.$route.params.id
    if (groupId) {
      findUserGroup(groupId).then((response) => {
        this.selectedUGItem = response.data
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    }
  },
  watch: {
    userGroupSearch() {
      this.getUserGroups()
    },
    selectedUGItem(value) {
      this.dataTabs = value !== null
    }
  },
  methods: {
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    toggleBC(event) {
      this.$refs.bctree.toggle(event);
    },
    getUserGroups() {
      getList('USERGROUP', this.currentPage, this.userGroupSearch, this.UGTableRows, this.orgId).then((response) => {
        this.userGroups = response.data.list
        this.userGroups.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalUGRecords = response.data.count
        this.loading = false
      })
    },
    selectTablePage(item) {
      this.currentPage = item.page
      this.getUserGroups()
    },
    getOrganizations() {
      getTree(null, this.treeParams).then((response) => {
        this.organizations = response.data.map(item => {
          const container = {data: {}};
          container.data.name = item.name;
          container.leaf = !item.hasChildren
          container.key = item.id;
          return container;
        })
        if (this.organizations.length > 0) {
          this.appendNode(this.organizations[0])
          this.expandedOrganizations[this.organizations[0].key] = true;
        }
      })
    },
    setOrgId(org, type = 'select') {
      this.activeTab = 0;
      if (type === 'edit') {
        console.log('edit')
        this.selectedUGItem.orgId = org.key;
        this.selectedUGItem.orgName = org.data.name;
        this.selectParentOrgDialog = false
      }
      if (type === 'new') {
        console.log('new')
        this.newUserGroup.orgId = org.key;
        this.newUserGroup.orgName = org.data.name;
        this.selectParentOrgDialog = false
      }

      if (type === 'select') {
        console.log('select')
        localStorage.setItem('orgId', org.key)
        this.orgId = org.key
        this.isEditable = false
        this.getOrganization()
      }
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getUserGroups()
        }
      }, 100);
    },
    redirectToItem(item) {
      router.push({path: `/user-groups/${item.data.id}`})
    },
    appendNode(item) {
      let names = []
      if (item.key !== "1") {
        names = [...item.data.parentNames, item.data.name]
      }
      getTree(item.key).then((response) => {
        item.children = response.data.map(element => {
          const container = {data: {}};
          container.data.name = element.name;
          container.leaf = !element.hasChildren
          container.key = element.id;
          container.data.parentNames = names
          return container;
        })
      })
    },
    initNewTextTemplateDialog() {
      this.newTextTemplateDialog = true
    },
    createTextTemplate() {
      create(this.newTextTemplate.text, this.newTextTemplate.userGroupIds).then((response) => {
        this.newTextTemplateDialog = false
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  //padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 10px;
  }

}
</style>
